<template>
  <div>
    <div class="card">
      <div class="px-1 rounded mt-2">
        <div class="row gy-1">
          <section id="basic-tabs-components">
            <div class="row match-height">
              <div class="col-12">
                <ul class="nav nav-tabs" role="tablist">
                  <li
                      class="nav-item"
                      :class="`${tabType == i ? 'active' : ''}`"
                      v-for="(tab, i) in tabs"
                      :key="i"
                      @click="onClickTab(i, tab)"
                      type="button"
                  >
                    <a :class="`${tabType == i ? 'active' : ''}`" class="nav-link" data-bs-toggle="tab"
                       aria-controls="home" role="tab" aria-selected="true">{{ tab }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>

      <div class="card-body">
        <div class="row d-flex flex-wrap justify-content-between align-items-center">
          <template v-if="tabType === 'tds_vds_rate_list'">
            <TdsVdsListTable
                :list-data="tdsVdsRateList"
            />
          </template>

          <template v-if="tabType === 'tds_rate'">
            <TdsListTable
                :list-data="tdsRateList"
            />
          </template>

          <template v-if="tabType === 'vds_rate'">
            <VdsListTable
                :list-data="vdsRateList"
            />
          </template>
        </div>
      </div>

      <GlobalLoader/>
    </div>
  </div>
</template>
<script setup>
import {computed, inject, onMounted, ref} from 'vue';
import {useRoute, useRouter} from "vue-router";
import handleInventory from "@/services/modules/inventory";
import TdsVdsListTable from '@/components/molecule/procurement/tds-vds/TdsVdsListingTable'
import TdsListTable from '@/components/molecule/procurement/tds-vds/TdsListingTable'
import VdsListTable from '@/components/molecule/procurement/tds-vds/VdsListingTable'
import handleTdsVds from "@/services/modules/procurement/tdsVds";

const router = useRouter();
const route = useRoute();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const emitter = inject('emitter');
const offset = ref(20);
const tabs = computed(() => {
  return homeContent.value.tds_vds_tab_types;
})

let loader = ref(false);
let saveButtonLoader = ref(false);
let saveNewButtonLoader = ref(false);
let tdsVdsRateList = ref({});
let tdsRateList = ref({});
let vdsRateList = ref({});
let tabType = ref('tds_vds_rate_list');
let tabTypeName = ref('TDS & VDS Rate List');
let homeContent = ref({});
let errors = ref({});
let companyId = computed(() => route.params.companyId);
let page = ref(1);
let {fetchHome, chartLoading} = handleInventory();
let {
    fetchTdsVdsRates,
    fetchTdsRates,
    fetchVdsRates
} = handleTdsVds();
let formData = ref({
  name: null,
  code: null,
})

function navigateToListPage() {
  router.push({name: `tds-vds-rate-list`, params: route.params, query: route.query});
}

function handleSubmit(redirect = false) {
  console.log('handleSubmit');
}

function showNewTeamMemberModal() {
  //teamMember.value?.toggleModal()
}


function onClickTab(tab, name) {
  tabType.value = tab;
  tabTypeName.value = name;

  if (tab === 'tds_vds_rate_list'){
    getTdsVdsRateList();
  }

  if (tab === 'tds_rate'){
    getTdsRateList();
  }

  if (tab === 'vds_rate'){
    getVdsRateList();
  }
}

async function getTdsVdsRateList() {
  const tdsVdsRes = fetchTdsVdsRates(getQuery());
  loader.value = true;

  await Promise.all([
    tdsVdsRes.then(res => {
      if (res.data) {
        tdsVdsRateList.value = res.data;
      } else {
        tdsVdsRateList.value = {};
      }
    }),
  ]).then(() => {
    loader.value = false
  }).catch((err) => {
    loader.value = false
  });
}

async function getTdsRateList() {
  const tdsRes = fetchTdsRates(getQuery());
  loader.value = true;

  await Promise.all([
    tdsRes.then(res => {
      if (res.data) {
        tdsRateList.value = res.data;
      } else {
        tdsRateList.value = {};
      }
    }),
  ]).then(() => {
    loader.value = false
  }).catch((err) => {
    loader.value = false
  });
}

async function getVdsRateList() {
  const tdsRes = fetchVdsRates(getQuery());
  loader.value = true;

  await Promise.all([
    tdsRes.then(res => {
      if (res.data) {
        vdsRateList.value = res.data;
      } else {
        vdsRateList.value = {};
      }
    }),
  ]).then(() => {
    loader.value = false
  }).catch((err) => {
    loader.value = false
  });
}

function resetForm() {
  formData.value = {
    name: null,
    code: null,
  }
}

function getQuery() {
  let query = '?company_id=' + companyId.value
  query += '&offset=' + offset.value
  if (page.value) query += '&page=' + page.value
  return query
}

emitter.on('onClickAddTds', (event) => {
  //
});

emitter.on('onTdsUpdate', async ({data}) => {
  //
});

onMounted(async () => {
  loader.value = true;
  const homeRes = fetchHome();
  const tdsVdsRes = fetchTdsVdsRates(getQuery());

  await Promise.all([
    homeRes.then(res => {
      if (res.data) {
        homeContent.value = res.data;
      }
    }),
    tdsVdsRes.then(res => {
      if (res.data) {
        tdsVdsRateList.value = res.data;
      }else{
        tdsVdsRateList.value = {};
      }
    }),
  ]).then(() => {
    loader.value = false
  }).catch((err) => {
    loader.value = false
  });
});

</script>