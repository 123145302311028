<template>
  <div class="card p-2 pt-0" style="min-height: 200px">
    <div class="row">
      <div class="col-md-12 text-right mb-2">
        <button @click="AddNew"
                class="btn btn-primary btn-block waves-effect waves-float waves-light">
          Add New
        </button>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Compliance Head</th>
          <th>TDS Section Reference</th>
          <th>TDS Rate</th>
          <th>VDS Section Reference</th>
          <th>VDS Rate</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in listData.data" :key="i">
          <td>{{ item.compliance_head }}</td>
          <td>{{ item.tds_rate.tds_section_ref }}</td>
          <td>{{ item.tds_rate.tds_rate }}</td>
          <td>{{ item.vds_rate.vds_section_ref }}</td>
          <td>{{ item.vds_rate.vds_rate }}</td>
          <td>{{ item.status }}</td>
          <td>
            <button v-if="item.company_id" class="btn btn-primary btn-sm"
                    @click="goToView(item)"
                    style="cursor: pointer; margin-left: 10px"
            >
              <i class="fas fa-eye"></i>
            </button>
            <button v-if="item.company_id" class="btn btn-secondary btn-sm"
                    @click="goToEdit(item)"
                    style="cursor: pointer; margin-left: 10px"
            >
              <i class="fas fa-edit"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-if="!listData.total" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {ref}                 from "vue";

const props  = defineProps({
  listData     : Object,
});
const route  = useRoute();
const router = useRouter();

const goToView = (item) => {
  //
}

const goToEdit = (item) => {
  //
}

function AddNew() {
  console.log('AddNew clicked on Tds Vds Rate List')
}
</script>
