import Network from '@/services/network';

export default function handleTdsVds() {
    const network = new Network;

    const fetchTdsVdsRates = (query) => {
        return network.api('get', '/procurement/tds-vds-rate/' + query);
    }

    const fetchTdsRates = (query) => {
        return network.api('get', '/procurement/tds-rate/' + query);
    }

    const fetchVdsRates = (query) => {
        return network.api('get', '/procurement/vds-rate/' + query);
    }

    const fetchTdsRateList = (query) => {
        return network.api('get', '/procurement/tds-rate/tds-list' + query);
    }

    const fetchVdsRateList = (query) => {
        return network.api('get', '/procurement/vds-rate/vds-list' + query);
    }

    return {
        fetchTdsVdsRates,
        fetchTdsRates,
        fetchVdsRates,
        fetchTdsRateList,
        fetchVdsRateList
    }
}